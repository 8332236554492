const books: any = {
    Gn: "gn",
    Ex: "ex",
    Lv: "lv",
    Nm: "nm",
    Dt: "dt",
    Joz: "joz",
    Sdc: "sdc",
    Rút: "rut",
    "1Sam": "1sam",
    "2Sam": "2sam",
    "1Krľ": "1krl",
    "2Krľ": "2krl",
    "1Krn": "1krn",
    "2Krn": "2krn",
    Ezd: "ezd",
    Neh: "neh",
    Est: "est",
    Jób: "job",
    Ž: "z",
    Prís: "pris",
    Koh: "koh",
    Vľp: "vlp",
    Iz: "iz",
    Jer: "jer",
    Nár: "nar",
    Ez: "ez",
    Dan: "dan",
    Oz: "oz",
    Joel: "joel",
    Am: "am",
    Abd: "abd",
    Jon: "jon",
    Mich: "mich",
    Nah: "nah",
    Hab: "hab",
    Sof: "sof",
    Ag: "ag",
    Zach: "zach",
    Mal: "mal",
    Tob: "tob",
    Jdt: "jdt",
    EstG: "estgr",
    Múd: "mud",
    Sir: "sir",
    Bar: "bar",
    DanG: "dangr",
    "1Mak": "1mak",
    "2Mak": "2mak",
    Mt: "mt",
    Mk: "mk",
    Lk: "lk",
    Jn: "jn",
    Sk: "sk",
    Rim: "rim",
    "1Kor": "1kor",
    "2Kor": "2kor",
    Ga: "ga",
    Ef: "ef",
    Flp: "flp",
    Kol: "kol",
    "1Tes": "1tes",
    "2Tes": "2tes",
    "1Tim": "1tim",
    "2Tim": "2tim",
    Tít: "tit",
    Flm: "flm",
    Heb: "heb",
    Jk: "jk",
    "1Pt": "1pt",
    "2Pt": "2pt",
    "1Jn": "1jn",
    "2Jn": "2jn",
    "3Jn": "3jn",
    Júd: "jud",
    Zj: "zj",
}

export const addBibleReferences = (text: string) => {
    let m
    let notes = []

    const notesRegex = /<note.*?>.*?<\/note>/gm

    /**
     *  Najprv vyberieme všetky <note>,
     *  keďže ich môže byť aj niekoľko a zároveň
     *  s nimi potrebujem manipulovať ďalej samostatne
     */
    while ((m = notesRegex.exec(text)) !== null) {
        // ak už je posledný match, aby nebol loop donekonečna
        if (m.index === notesRegex.lastIndex) {
            notesRegex.lastIndex++
        } else {
            notes.push(m)
        }
    }


    const xtAndxdcRegex = /<char class="(xt.*?|xdc.*?)".*?>(?!<)(.*?)<\/char>/gm;
    const referenceRegex = /(<char class="xo".*?>.*?<\/char>)/gm

    let newNotes: any[] = []

    /**
     *  Pre každú nájdenú <note> hľadáme
     *  "xt" alebo "xdc" referencie, ktoré
     *  spojíme do jednej referencie a vytvoríme "xt"
     */
    notes.forEach((note: any) => {
        let k
        let references = ""

        while ((k = xtAndxdcRegex.exec(note[0])) !== null) {
            if (k.index === xtAndxdcRegex.lastIndex) {
                xtAndxdcRegex.lastIndex++
            } else {
                references = references + k[2]
            }
        }

        var refs = references.split(";")
        var book = ""
        var newReferences = ""

        refs.forEach((ref, index) => {

            if (ref.length == 0) return

            ref = ref.trim()

            let parts = ref.split(" ")
            let nextBook = parts[0]
            let partIndex: any = false

            //if (!nextBook.includes(",")) {
            if (parts.length > 1) {
                book = nextBook
                partIndex = 1
            } else {
                partIndex = 0
            }

            let chapter = parts[partIndex].split(",")[0]

            let verses = parts[partIndex].split(".")

            verses.forEach((verseItem: any) => {
                let verseParts: any = false

                verseItem = verseItem.split(",")
                
                if (verseItem[1]) {
                    verseParts = verseItem[1]
                } else {
                    verseParts = verseItem[0]
                }

                verseParts = verseParts.split("-")

                let startVerse = false
                let endingVerse = false

                if (verseParts[1]) {
                    startVerse = verseParts[0]
                    endingVerse = verseParts[1]
                } else {
                    startVerse = verseParts[0]
                }

                if (endingVerse) {
                    newReferences =
                        newReferences +
                        "<a class='reference-click hover:underline hover:text-gray-200' href='/citanie/seb/" +
                        books[book] +
                        "/" +
                        chapter +
                        "/" +
                        startVerse +
                        "/" +
                        endingVerse +
                        "'>" +
                        book +
                        " " +
                        chapter +
                        "," +
                        startVerse +
                        "-" +
                        endingVerse +
                        "</a>"
                } else {
                    newReferences =
                        newReferences +
                        "<a class='reference-click hover:underline hover:text-gray-200' href='/citanie/seb/" +
                        books[book] +
                        "/" +
                        chapter +
                        "/" +
                        startVerse +
                        "'>" +
                        book +
                        " " +
                        chapter +
                        "," +
                        startVerse +
                        "</a>"
                }

                newReferences = newReferences + "; "

            })
        })

        // odstránenie poslednej bodkočiarky
        newReferences = newReferences.slice(0, -2)

        note = note[0].replace(
            referenceRegex,
            '$1<char class="xt-reference cursor-auto invisible opacity-0 fixed left-0 right-0 bottom-0 p-4 text-center border-t border-blue-500 bg-sky-500 text-white transition ease-in-out duration-100 z-30" closed="false">' +
                newReferences +
                "</char>"
        )

        newNotes.push(note)
    })

    let i = 0

    /**
     *  Znova prejdeme všetky nájdené <note>
     *  a doplníme ich obsah o vytvorenú "xt" referenciu
     */
    while ((m = notesRegex.exec(text)) !== null) {
        if (m.index === notesRegex.lastIndex) {
            notesRegex.lastIndex++
        } else {
            text = text.replace(m[0], newNotes[i])
            i++
        }
    }

    /**
     *  K "xo" ešte zmeníme vzhľad
     */
    text = text.replace(
        /(<char class="xo)(")(.*?>)(.*?)(<\/char>)/g,
        '$1 ml-1 px-2 bg-gray-100 rounded-lg text-gray-500 hover:bg-gray-200 hover:bg-sky-500 hover:text-white" $3Ref. $4$5'
    )

    return text
}
