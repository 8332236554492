export const addBibleNotes = (text: string) => {
    text = text.replace(
        /(<char class="fr)(")(.*?>)(.*?)(<\/char>)/g,
        '$1 ml-1 px-2 bg-gray-100 rounded-lg text-gray-500 hover:bg-gray-200 hover:bg-green-500 hover:text-white" $3Pozn.$5'
    )

    text = text.replace(/style/g, "class")

    return text
}
