import { useGlobalStore } from "@/store/global"
// import { addBibleNotes } from "@/utils/addBibleNotes"
// import { addBibleReferences } from "@/utils/addBibleReferences"

export const prepareText = (text: string) => {
    const store = useGlobalStore()

    text = text.replace("<a>", "")
    text = text.replace("</a>", "")

    /*
    const regex = /(.*)(<para.*?class=")(.*?)(".*?>)(.*)/gms;
    let m;

    while ((m = regex.exec(text)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
            regex.lastIndex++;
        }

        text = "";
        
        // The result can be accessed through the `m`-variable.
        m.forEach((match, groupIndex) => {   
                if(groupIndex == 0){
            
            }else {
            
                text = text + match;
                
                if(groupIndex == 3){
                    text = text + " group-hover"
                }
            
            }
            
        });
    }
    */

    //text = text.replaceAll('class="xt"', 'class="xt cursor-auto invisible opacity-0 fixed left-0 right-0 bottom-0 p-4 text-center border-t bg-sky-500 text-white transition ease-in-out duration-100"')
    text = text.replace(/class="xt"/g, 'class="xt hidden"')
    text = text.replace(/class="xdc"/g, 'class="xdc hidden"')
    text = text.replace(
        /class="ft"/g,
        'class="ft cursor-auto invisible opacity-0 fixed left-0 right-0 bottom-0 p-4 text-center border-t border-green-500 bg-green-500 text-white transition ease-in-out duration-100 z-30"'
    )

    if (!store.getSettings.showTitleHeadings) {
        text = text.replace("font-size: 18px;", "font-size: 18px;display:none")
    }

    if (store.getSettings.showBibleNotes) {
        text = text.replace(/class="f"/g, 'class="f inline-block bg-white"')
        text = addBibleNotes(text.trim())
    } else {
        text = text.replace(
            /class="f"/g,
            'class="f inline-block hidden bg-white"'
        )
    }

    if (store.getSettings.showBibleReferences) {
        text = text.replace(/class="x"/g, 'class="x inline-block bg-white"')
        text = addBibleReferences(text.trim())
    } else {
        text = text.replace(
            /class="x"/g,
            'class="x inline-block hidden bg-white"'
        )
    }

    text = text.replace(
        /class="f"/g,
        'class="f inline-block hidden bg-white"'
    )
    
    text = text.replace(
        /class="x"/g,
        'class="x inline-block hidden bg-white"'
    )

    return text
}
